import { forEach, isEmpty, isNil } from "lodash";
import {
  Discount,
  DiscountData,
  InvoiceMovementType,
  InvoicePosition,
  InvoicePositionData,
  InvoiceType,
} from "@planetadeleste/vue-mc-gw";
import { InvoiceModule } from "@/store/invoice";
import { number } from "mathjs";
import useMath from "@/composables/math";
import { reverseTax, round } from "@/plugins/helpers";

const { math } = useMath();

/**
 * Apply positions discounts to final price
 *
 * @date 11/11/2022 - 09:24:26
 * @author Planeta del Este
 */
export const applyDiscounts = (
  positions: InvoicePosition[],
  discounts: DiscountData[],
  priceWithTax = false
) => {
  applyPositionsDiscount(positions);

  forEach(discounts, (obDiscountData) => {
    const obDiscount =
      obDiscountData instanceof Discount
        ? obDiscountData
        : new Discount(obDiscountData);

    if (!obDiscount.value) {
      return;
    }

    applyPositionsDiscount(positions, obDiscount, priceWithTax);
  });
};

export const applyPositionsDiscount = (
  positions: InvoicePosition[],
  obDiscount?: Discount,
  priceWithTax = false
) => {
  forEach(positions, (obPosition) => {
    if (!(obPosition instanceof InvoicePosition)) {
      obPosition = new InvoicePosition(obPosition);
    }

    const sField =
      !obDiscount ||
      obDiscount.priority === 1 ||
      !obPosition.price_with_discounts
        ? "price_without_tax"
        : "price_with_discounts";
    const fPrice = number(obPosition.get(sField, obPosition.price));

    if (!fPrice) {
      obPosition.set("price_with_discounts", 0);
      return;
    }

    if (priceWithTax && obDiscount && obDiscount.fixed) {
      const fTax = number(obPosition.get("offer.tax_percent_value", 0));
      if (fTax) {
        const fValue = round(
          math.subtract(obDiscount.value, reverseTax(obDiscount.value, fTax))
        );
        obDiscount.set("value", fValue);
      }
    }

    const fPriceDiscount = obDiscount ? obDiscount.calculate(fPrice) : 0;
    const fPriceWithDiscount = math.add(fPrice, fPriceDiscount);
    obPosition.set("price_with_discounts", fPriceWithDiscount);
  });
};

/**
 * Check position is invalid
 * @date 29/7/2022 - 05:37:14
 * @author Planeta del Este
 *
 * @param {Partial<InvoicePositionData>} obPositionData
 * @returns {boolean} Returns true if item is valid, otherwise false
 */
export const invalidItemPosition = (
  obPositionData: Partial<InvoicePositionData>
): boolean => {
  // Check if it has price
  if (isNil(obPositionData) || isEmpty(obPositionData)) {
    return true;
  }

  // Invoice movement as debt require item_id as reference
  if (
    [InvoiceMovementType.CODE_DEBT, 15].includes(InvoiceModule.iMovementType)
  ) {
    return !obPositionData.item_id;
  }

  const sTypeCode = InvoiceModule.sTypeCode;

  // Invoice type e-Resguardo require cfe_code property
  if (sTypeCode === InvoiceType.CODE_ERESGUARDO) {
    return isNil(obPositionData.cfe_code) || isEmpty(obPositionData.cfe_code);
  }

  // Invoice type e-Remito don't require offer
  if (sTypeCode === InvoiceType.CODE_EREMITO) {
    return true;
  }

  // All rest of invoice type require offer object and unit_price
  if (
    !InvoiceModule.sTypeCode ||
    InvoiceModule.sTypeCode !== InvoiceType.CODE_ERESGUARDO
  ) {
    return isNil(obPositionData.offer) || isEmpty(obPositionData.offer);
  }

  return false;
};
